import axios from 'axios';

const client = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  json: true
})

client.interceptors.response.use(
  response => response,
  error => {
    const status = error.response ? error.response.status : null
    if (status === 401) {
      // token has expired, log out the user
      // clear the token from localStorage and redirect to the login page
      localStorage.clear()
      location.href = `${process.env.VUE_APP_HOME}?logout=true`
    }
    return Promise.reject(error)
  }
)


export default {
  async request (method, url, data, req) {
    let requester = req || null
    let headers = {ContentType: `application/json`}
    let accessToken = localStorage.getItem('jwt') || null;
    let token = accessToken
    Object.assign(headers, {  Authorization: `Bearer ${token}` })
    
    requester = client
    
    return requester({
      method,
      url: url,
      data,
      headers : headers,
      timeout: 1200000
    }).then(req => {
      return req.data
    })
  },

  async getBase64 (method, url, req, data) {
    let requester = req || null
    let headers = {ContentType: `application/json`}
    let accessToken = localStorage.getItem('token') || null;
    Object.assign(headers, {  Authorization: `Bearer ${accessToken}` })
    
    requester = client

    return requester({
      method: method,
      url: url,
      data,
      headers : headers,
      reponseType:'arraybuffer',
      timeout: 1200000
    }).then(req => {
      return req.data
    })
  },
  
  upload(method, url, data) {
    let headers = {"Content-Type": "multipart/form-data"}
    let accessToken = localStorage.getItem('jwt') || null;
    let token = accessToken
    Object.assign(headers, {  Authorization: `Bearer ${token}` })
    let requester = client

    return requester({
      method: method,
      url: url,
      data: data,
      headers : headers,
      reponseType:'arraybuffer',
      timeout: 1200000
    }).then(req => {
      return req.data
    })
  }
}