import businessService from '../../services/business.service'

export const state = {
    businesses: [],
    selectedBusiness: {},
    products: [],
};

export const mutations = {
    ADD_BUSINESS(state, value) {
        state.businesses.push(value)
    },

    UPDATE_BUSINESS(state, value) {
        let index = state.businesses.findIndex(business => business.id == value.id)
        state.businesses[index] = value
    },

    SET_BUSINESSES(state, value) {
        state.businesses = value
    },

    SET_ONE_BUSINESS(state, value) {
        state.selectedBusiness = value[0]
        state.selectedBusiness.products.forEach(product => {
            product.image = JSON.parse(product.image)
            product.images = JSON.parse(product.images)
            product.options = JSON.parse(product.options)
            product.variants = JSON.parse(product.variants)
        })
    },

    DELETE_BUSINESS(state, value) {
        let index = state.businesses.findIndex((res) => res.id == value)
        state.businesses.splice(index, 1)
    },

    UPDATE_EMITTED_PRODUCT(state, value) {
        state.businesses.forEach(stream => {
            let index = stream.products.findIndex((res) => res.id == value.id)
            console.log('index', index)
            if(index > -1) {
                state.selectedBusiness = stream
                state.selectedBusiness.products[index] = value 
                stream.products[index] = value 
            }
        })
    },

    ADD_PRODUCT(state, value) {
        state.products.push(value)
    },
};

export const actions = {
    async createBusiness({commit}, payload) {
        try {
            let response = await businessService.create(payload)
            commit('ADD_BUSINESS', response[0])
            return response
        }
        catch(error) {
            console.log('create business action', error)
        }
    },
    
    async updateBusiness({commit}, payload) {
        try {
            let response = await businessService.update(payload)
            commit('UPDATE_BUSINESS', response[0])
            return response
        }
        catch(error) {
            console.log('update business action', error)
        }
    },

    async findAllBusinesses({ commit }) {
        try {
            let response = await businessService.findAll()
            commit('SET_BUSINESSES', response)
            return response
        }
        catch(error) {
            console.log('find all businesses action', error)
        }
    },

    async findOneBusiness({ commit }, id) {
        try {
            let response = await businessService.findOne(id)
            commit('SET_ONE_BUSINESS', response)
            return response
        }
        catch(error) {
            console.log('find one business action', error)
        }
    },

    async deleteBusiness({commit}, id) {
        try {
            let response = await businessService.delete(id)
            commit('DELETE_BUSINESS', id)
            return response
        }
        catch(error) {
            console.log('delete business action', error)
        }
    },

    async setBusiness({ commit }, payload) {
        commit('SET_ONE_BUSINESS', payload)
        console.log('set business', payload)
        return payload
    },

    getBusiness({state}) {
        return state.selectedBusiness
    },

    async updateEmittedProduct({ commit }, payload) {
        commit('UPDATE_EMITTED_PRODUCT', payload)
        return payload
    },

    async addProduct({commit}, payload) {
        try {
            let response = await businessService.addProduct(payload)
            commit('SET_ONE_BUSINESS', response)
            return response
        }
        catch(error) {
            console.log('add product action', error)
        }
    },

    async editProduct({commit}, payload) {
        try {
            let response = await businessService.editProduct(payload)
            commit('SET_ONE_BUSINESS', response)
            return response
        }
        catch(error) {
            console.log('add product action', error)
        }
    },

    async deleteProduct({commit}, id) {
        try {
            let response = await businessService.deleteProduct(id)
            commit('SET_ONE_BUSINESS', response)
            return response
        }
        catch(error) {
            console.log('delete product action', error)
        }
    },

    async findOrdersByBusiness({ commit }, id) {
        try {
            let response = await businessService.findOrdersByBusiness(id)
            //commit('SET_ONE_BUSINESS', response)
            return response
        }
        catch(error) {
            console.log('find orders by business action', error)
        }
    },

    async getUpdatedVariants({ commit }, business) {
        try {
            
            business.products.forEach((product, index) => {
                business.products[index].variants.forEach((variant, vindex) => {
                    business.products[index].variants[vindex].totalProfit = (variant.totalProfit || 0) / 100
                    business.products[index].variants[vindex].profit = (variant.profit || 0) / 100
                    business.products[index].variants[vindex].commission = (variant.commission || 0) / 100
                })
            })
            return business
        }
        catch(error) {
            console.log('find orders by business action', error)
        }
    },

};

export const getters = {
    
};