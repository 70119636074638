import store from "@/state/store";
//import router from '@/router';

export default [
  {
    path: "/",
    name: "dashboard",
    meta: {
      title: "Dashboard",
      authRequired: true,
    },
    component: () => import("../pages/dashboard/index.vue"),
  },
  {
    path: "/authenticate",
    name: "authenticate",
    meta: {
      title: "Authenticate",
      authRequired: false,
    },
    component: () => import("../pages/auth/Authenticate.vue"),
  },
  {
    path: "/business-center",
    name: "business-center",
    meta: {
      title: "Business Center",
      authRequired: true,
    },
    component: () => import("../pages/business/BusinessCenter.vue"),
  },
  {
    path: "/finance-manager",
    name: "finance-manager",
    component: () => import("../pages/finance/Finance.vue"),
    meta: {
      title: "Finance Manager",
      authRequired: true,
    },
  },
  {
    path: "/marketplace",
    name: "marketplace",
    meta: {
      title: "Marketplace",
      authRequired: true,
    },
    component: () => import("../pages/marketplace/Marketplace.vue"),
  },
  {
    path: "/community",
    name: "community",
    meta: {
      title: "Community",
      authRequired: true,
    },
    component: () => import("../pages/community/Community.vue"),
  },
  {
    path: "/terms-of-service",
    name: "terms-of-service",
    component: () => import("../pages/policies/TermsOfService.vue"),
    meta: {
      title: "Terms of Service",
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters["auth/loggedIn"]) {
          next({ name: "dashboard" });
        } else {
          next();
        }
      },
    },
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () => import("../pages/policies/PrivacyPolicy.vue"),
    meta: {
      title: "Privacy Policy",
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters["auth/loggedIn"]) {
          next({ name: "dashboard" });
        } else {
          next();
        }
      },
    },
  },
  {
    path: "/refund-and-return-policy",
    name: "refund-and-return-policy",
    component: () => import("../pages/policies/RefundReturnPolicy.vue"),
    meta: {
      title: "Refund & Return Policy",
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters["auth/loggedIn"]) {
          next({ name: "dashboard" });
        } else {
          next();
        }
      },
    },
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout", 
      authRequired: true,
      beforeEnter(routeTo, routeFrom, next) {
        if (store.getters["auth/loggedIn"]) {
          next({ name: "login" });
        } else {
          next();
        }
      },
    },
    component: () => import("../pages/auth/Logout")
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../pages/auth/ForgotPassword.vue"),
    meta: {
      title: "Forgot Password",
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters["auth/loggedIn"]) {
          next({ name: "dashboard" });
        } else {
          next();
        }
      },
    },
  },  
  {
    path: "/business/create",
    name: "create-business",
    meta: {
      title: "Create Business",
      authRequired: true,
    },
    component: () => import("../pages/business/CreateBusiness.vue"),
  },
  {
    path: "/business/edit",
    name: "edit-business",
    meta: {
      title: "Edit Business",
      authRequired: true,
    },
    component: () => import("../pages/business/EditBusiness.vue"),
  },
  {
    path: "/business",
    name: "business",
    meta: {
      title: "Business",
      authRequired: true,
    },
    component: () => import("../pages/business/Business.vue"),
  },
  {
    path: "/portfolio",
    name: "portfolio",
    meta: {
      title: "Portfolio",
      authRequired: true,
    },
    component: () => import("../pages/portfolio/Portfolio.vue"),
  },
  {
    path: "/checkout",
    name: "checkout",
    meta: {
      title: "Checkout",
      authRequired: true,
    },
    component: () => import("../pages/orders/Checkout.vue"),
  },
  {
    path: "/orders",
    name: "orders",
    meta: {
      title: "Orders",
      authRequired: true,
    },
    component: () => import("../pages/orders/Orders.vue"),
  },
  {
    path: "/order/details",
    name: "order-details",
    meta: {
      title: "Order Details",
      authRequired: true,
    },
    component: () => import("../pages/orders/OrderDetails.vue"),
  },
  {
    path: "/product",
    name: "product",
    meta: {
      title: "Product",
    },
    component: () => import("../pages/product/Product.vue"),
  },
  {
    path: "/cart",
    name: "cart",
    meta: {
      title: "Cart",
    },
    component: () => import("../pages/orders/ShoppingCart.vue"),
  },
  {
    path: "/invoice",
    name: "invoice",
    meta: {
      title: "Invoice",
    },
    component: () => import("../pages/orders/Invoice.vue"),
  },
  {
    path: "/settings",
    name: "settings",
    meta: {
      title: "Settings",
    },
    component: () => import("../pages/settings/Settings.vue"),
  },
  { 
    path: '/:pathMatch(.*)*', 
    name: '404', 
    component: () => import("../pages/general/404.vue"),
  },
];