import http from "../common/http";

class ProductService {

  findAllProducts() {
    return http.request('get', `/product/find-all`);
  }

  findOneProduct(id) {
    return http.request('get', `/product/find-one?id=${id}`);
  }
}

export default new ProductService();
